.upload-detail-container{
    padding-top: 20px;
}
.upload-detail-container {
    .subheading{
        font-weight: bold;    
    }
    .detail-row{
        margin-bottom: 15px !important;
    }
}